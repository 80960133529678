.experience-heading {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Regular";
  line-height: normal;
  text-align: center;
}

.experience-accord {
  margin: 50px;
}

.accord-panel {
  font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
  .experience-accord {
    margin: 20px;
  }
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }
}
